import React, { useEffect } from "react";

import shallow from "zustand/shallow";

import StorageHandler from "../../../../../utils/StorageHandler";
import { navigate } from "../../../../shared/LinkComponent";
import useVehicleStore from "../../HoyerPay/VehiclesDashboard/store";
import useStore, { sections } from "../store";
import Navbar from "./Navbar";

const storage = new StorageHandler();

interface iNavbarWrapper {
    location: Location;
}
const NavbarWrapper = ({ location }: iNavbarWrapper) => {
    const [canManageHoyerPay, checkCanManageHoyerPay] = useVehicleStore(
        (state) => [state.canCreate, state.checkCanCreate],
        shallow
    );

    const [
        loading,
        data,
        selectedCustomerNumber,
        setSelectedSection,
        fetchData,
    ] = useStore(
        (state) => [
            state.loading,
            state.data,
            state.selectedCustomerNumber,
            state.setSelectedSection,
            state.fetchData,
        ],
        shallow
    );
    useEffect(() => {
        if (selectedCustomerNumber) {
            checkCanManageHoyerPay(selectedCustomerNumber);
        }
    }, [selectedCustomerNumber]);

    useEffect(() => {
        if (data === null) {
            fetchData(storage.get("selectedCustomerNumber", undefined));
        }
        const startSection = sections.find(
            (section) => section.url === location.pathname
        );
        if (startSection) {
            setSelectedSection(startSection);
        } else {
            navigate(sections[0].url);
            setSelectedSection(sections[0]);
        }
    }, [location]);

    return <Navbar canManageHoyerPay={canManageHoyerPay} />;
};

export default NavbarWrapper;
