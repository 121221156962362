import React, { useEffect, useState } from "react";

import { graphql } from "gatsby";
import { useIsSSR } from "react-aria";
import { Helmet } from "react-helmet";

import { css } from "@emotion/react";
import styled from "@emotion/styled";

import NavbarWrapper from "../../../components/pages/Hcm/Dashboard/parts/NavbarWrapper";
import Collapsible from "../../../components/shared/Collapsible";
import Markdown from "../../../components/shared/Markdown";
import Headline from "../../../components/shared/NewHeadline";
import Page from "../../../components/shared/Page";

const Container = styled.div`
    ${({ theme }) => css`
        ${theme.paddings.containerOuter.right}
        ${theme.paddings.containerOuter.bottom}

        color: ${theme.colors.blue.toString()};
    `}

    display: grid;
    grid-template-columns: 1fr;
    min-height: 80vh;
    ${({ theme }) =>
        theme.breakpoints.from.medium.css`
            grid-template-columns: 2fr 10fr;
    `}
`;

const Content = styled.div`
    padding-left: 30px;
    padding-bottom: 30px;
    min-height: 80vh;
    background-color: ${({ theme }) => theme.colors.white.toString()};
    ${({ theme }) => css`
        ${theme.paddings.containerInner.right}
    `};

    margin-top: 90px;
    ${({ theme }) =>
        theme.breakpoints.from.medium.css`
            margin-top: 30px;
    `}
`;

const SectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 50px;
`;

const HeadlineWithOffsetAnchor = styled(Headline)`
    scroll-margin-top: 5em;
`;

const PageHeadline = styled(Headline)`
    padding-bottom: 25px;
`;

const FaqPage = ({ data, location }) => {
    const isSSR = useIsSSR();

    const [showNav, setShowNav] = useState(!isSSR);

    const sections = data?.sections?.edges;
    const title = "Hoyer Card FAQ";

    useEffect(() => {
        if (!isSSR) {
            setShowNav(true);
        }
    }, [isSSR]);

    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta
                    name="description"
                    content="Häufige Fragen und Antworten zu allen Themen rund um die Hoyer Card."
                />
            </Helmet>
            <Page>
                <Container>
                    {showNav ? (
                        <NavbarWrapper location={location} />
                    ) : (
                        <div> </div>
                    )}
                    <Content>
                        <PageHeadline is={1} lookLike={1}>
                            {title}
                        </PageHeadline>
                        {(!sections || sections.length === 0) && (
                            <p>Es wurden keine FAQ gefunden.</p>
                        )}
                        {sections && sections.length > 0 && (
                            <SectionContainer>
                                {sections.map(({ node }) => (
                                    <FaqSection
                                        key={node.id}
                                        headline={node.name}
                                        templateId={node.yamlId}
                                        items={node?.faqItems}
                                    />
                                ))}
                            </SectionContainer>
                        )}
                    </Content>
                </Container>
            </Page>
        </>
    );
};

const FaqSection = ({ headline, templateId, items }) => {
    return (
        <div>
            <HeadlineWithOffsetAnchor id={templateId} lookLike={2}>
                {headline}
            </HeadlineWithOffsetAnchor>
            {items.map((item, index) => (
                <FaqItem
                    key={`${templateId}-${index}`}
                    question={item.question}
                    answer={item.answer}
                />
            ))}
        </div>
    );
};

const FaqItem = ({ question, answer }) => {
    return (
        <Collapsible title={question}>
            <Markdown>{answer}</Markdown>
        </Collapsible>
    );
};

export default FaqPage;

export const query = graphql`
    query {
        sections: allHoyerCardFaqYaml(
            filter: { disabled: { eq: false } }
            sort: { fields: rankOrder }
        ) {
            edges {
                node {
                    faqItems {
                        answer
                        question
                    }
                    id
                    name
                    yamlId
                }
            }
        }
    }
`;
